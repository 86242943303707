import React from 'react';
import '../styles/ActivityFeed.css';

const ActivityFeed = () => {
  return (
    <div className="activity-feed">
      <h2 className="text-lg font-semibold mb-4">활동</h2>
      <div className="space-y-4">
        <div className="flex items-center">
          <div className="w-8 h-8 bg-gray-300 rounded-full mr-2"></div>
          <p><span className="font-semibold">User1</span> 님이 회원님의 게시물을 좋아합니다.</p>
        </div>
        <div className="flex items-center">
          <div className="w-8 h-8 bg-gray-300 rounded-full mr-2"></div>
          <p><span className="font-semibold">User2</span> 님이 회원님을 팔로우하기 시작했습니다.</p>
        </div>
      </div>
    </div>
  );
};

export default ActivityFeed;