import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/EditProfile.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://kk.oyeoye.co.kr';

const EditProfile = () => {
  const [user, setUser] = useState({
    username: '',
    email: '',
    bio: '',
    profile_image: ''
  });
  const [originalUser, setOriginalUser] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [password, setPassword] = useState({ current: '', new: '', confirm: '' });
  const [errors, setErrors] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }
        const response = await axios.get(`${API_URL}/api/users/me`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUser(response.data);
        setOriginalUser(response.data);
        setPreview(getProfileImageUrl(response.data.profile_image));
      } catch (error) {
        //console.error('사용자 데이터 조회 중 오류:', error.response || error);
        setError('사용자 데이터를 불러오는데 실패했습니다.');
      }
    };
  
    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    setIsChanged(JSON.stringify(user) !== JSON.stringify(originalUser) || profileImage !== null || Object.values(password).some(value => value !== ''));
  }, [user, originalUser, profileImage, password]);

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'username':
        if (value.length < 2) error = '사용자 이름은 2자 이상이어야 합니다.';
        break;
      case 'email':
        if (!/\S+@\S+\.\S+/.test(value)) error = '유효한 이메일 주소를 입력해주세요.';
        break;
      case 'new':
        if (value && value.length < 6) error = '새 비밀번호는 6자 이상이어야 합니다.';
        break;
      case 'confirm':
        if (value !== password.new) error = '비밀번호가 일치하지 않습니다.';
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    setErrors({ ...errors, [name]: validateField(name, value) });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
    setErrors({ ...errors, [name]: validateField(name, value) });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  // 수정된 getProfileImageUrl 함수
  const getProfileImageUrl = (imagePath) => {
    if (!imagePath) return 'https://via.placeholder.com/150';
    if (imagePath.startsWith('http')) return imagePath;
    return `${API_URL}${imagePath}?t=${new Date().getTime()}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }
  
      const formData = new FormData();
      formData.append('username', user.username);
      formData.append('email', user.email);
      formData.append('bio', user.bio);
      if (profileImage) {
        formData.append('profile_image', profileImage);
      }
      if (password.new) {
        formData.append('current_password', password.current);
        formData.append('new_password', password.new);
      }
  
      const response = await axios.put(`${API_URL}/api/users/me`, formData, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
  
      setUser(response.data);
      setPreview(getProfileImageUrl(response.data.profile_image));
      alert('프로필이 성공적으로 업데이트되었습니다.');
      navigate(`/user/${response.data.username}`);
    } catch (error) {
      //console.error('프로필 업데이트 중 오류:', error.response?.data || error);
      setError(error.response?.data?.error || '프로필 업데이트에 실패했습니다.');
    }
  };

  const handleCancel = () => {
    navigate(`/user/${originalUser.username}`);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="edit-profile">
      <h2>프로필 편집</h2>
      <form onSubmit={handleSubmit}>
        <div className="profile-image-container">
          <img src={preview} alt="Profile" className="profile-image-preview" />
          <input type="file" onChange={handleImageChange} accept="image/*" />
        </div>
        <div>
          <label htmlFor="username">사용자 이름:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={user.username}
            onChange={handleChange}
          />
          {errors.username && <span className="error">{errors.username}</span>}
        </div>
        <div>
          <label htmlFor="email">이메일:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={user.email}
            onChange={handleChange}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
        <div>
          <label htmlFor="bio">소개:</label>
          <textarea
            id="bio"
            name="bio"
            value={user.bio}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="current_password">현재 비밀번호:</label>
          <input
            type="password"
            id="current_password"
            name="current"
            value={password.current}
            onChange={handlePasswordChange}
          />
        </div>
        <div>
          <label htmlFor="new_password">새 비밀번호:</label>
          <input
            type="password"
            id="new_password"
            name="new"
            value={password.new}
            onChange={handlePasswordChange}
          />
          {errors.new && <span className="error">{errors.new}</span>}
        </div>
        <div>
          <label htmlFor="confirm_password">새 비밀번호 확인:</label>
          <input
            type="password"
            id="confirm_password"
            name="confirm"
            value={password.confirm}
            onChange={handlePasswordChange}
          />
          {errors.confirm && <span className="error">{errors.confirm}</span>}
        </div>
        <div className="button-group">
          <button type="submit" disabled={!isChanged || Object.values(errors).some(error => error !== '')}>저장</button>
          <button type="button" onClick={handleCancel}>취소</button>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;