import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Send, ArrowLeft } from 'lucide-react';
import '../styles/DirectMessage.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://kk.oyeoye.co.kr';

const DirectMessage = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      fetchMessages(selectedUser.id);
    }
  }, [selectedUser]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/users`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchMessages = async (userId) => {
    try {
      const response = await axios.get(`${API_URL}/api/messages/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !selectedUser) return;

    try {
      const response = await axios.post(`${API_URL}/api/messages`, {
        recipientId: selectedUser.id,
        content: newMessage
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setMessages([...messages, response.data]);
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="direct-message">
      {!selectedUser ? (
        <div className="user-list">
          <h2 className="text-xl font-semibold mb-4">메시지</h2>
          {users.map(user => (
            <div key={user.id} onClick={() => setSelectedUser(user)} className="user-item">
              <img src={user.profileImage || '/default-avatar.png'} alt={user.username} className="w-12 h-12 rounded-full mr-3" />
              <div>
                <p className="font-semibold">{user.username}</p>
                <p className="text-gray-500 text-sm">최근 메시지 내용...</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="message-view">
          <div className="message-header">
            <button onClick={() => setSelectedUser(null)} className="mr-2"><ArrowLeft /></button>
            <img src={selectedUser.profileImage || '/default-avatar.png'} alt={selectedUser.username} className="w-8 h-8 rounded-full mr-2" />
            <span className="font-semibold">{selectedUser.username}</span>
          </div>
          <div className="message-list">
            {messages.map(message => (
              <div key={message.id} className={`message ${message.senderId === selectedUser.id ? 'received' : 'sent'}`}>
                {message.content}
              </div>
            ))}
          </div>
          <form onSubmit={sendMessage} className="message-input">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="메시지를 입력하세요..."
              className="flex-grow mr-2 p-2 rounded-full border border-gray-300"
            />
            <button type="submit" className="p-2 bg-blue-500 text-white rounded-full"><Send size={20} /></button>
          </form>
        </div>
      )}
    </div>
  );
};

export default DirectMessage;