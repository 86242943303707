import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Home, Search, PlusSquare, Heart, User, MessageCircle } from 'lucide-react';
import api from '../api';  // 경로 수정
import HomeFeed from './HomeFeed';
import ExplorePage from './ExplorePage';
import CreatePost from './CreatePost';
import ActivityFeed from './ActivityFeed';
import UserProfile from './UserProfile';
import EditProfile from './EditProfile';
import Login from './Login';
import Register from './Register';
import DirectMessage from './DirectMessage';
import EditPost from './EditPost';
import '../styles/App.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://kk.oyeoye.co.kr';

const Header = ({ isLoggedIn }) => {
  const navigate = useNavigate();

  return (
    <header className="app-header">
      <div className="header-content">
        <h1 className="logo">꼬물이네</h1>
        {isLoggedIn && (
          <div className="header-actions">
            <button onClick={() => navigate('/activity')} className="header-button">
              <Heart size={24} />
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

const Navigation = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <nav className="bottom-nav">
      <ul className="flex justify-between">
        <li><Link to="/" className={location.pathname === '/' ? 'active' : ''}><Home /></Link></li>
        <li><Link to="/search" className={location.pathname === '/search' ? 'active' : ''}><Search /></Link></li>
        <li><Link to="/create" className={location.pathname === '/create' ? 'active' : ''}><PlusSquare /></Link></li>
        <li><Link to="/direct" className={location.pathname === '/direct' ? 'active' : ''}><MessageCircle /></Link></li>
        <li>
          {isLoggedIn ? (
            <button onClick={() => {
              const username = localStorage.getItem('username');
              if (username) {
                navigate(`/user/${username}`);
              }
            }} className={location.pathname.startsWith('/user/') ? 'active' : ''}><User /></button>
          ) : (
            <Link to="/login" className={location.pathname === '/login' ? 'active' : ''}><User /></Link>
          )}
        </li>
      </ul>
    </nav>
  );
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const verifyToken = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await api.post('/api/auth/verify');
        if (response.data.valid) {
          setIsLoggedIn(true);
        } else {
          throw new Error('Invalid token');
        }
      } catch (error) {
        //console.error('Token verification error:', error);
        localStorage.removeItem('token');
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      verifyToken();
    }, 5 * 60 * 1000);  // 5분마다 토큰 검증

    return () => clearInterval(interval);
  }, [verifyToken]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="app-container">
        <Header isLoggedIn={isLoggedIn} />
        
        <main className="app-main">
          <Routes>
            <Route path="/" element={isLoggedIn ? <HomeFeed /> : <Navigate to="/login" />} />
            <Route path="/search" element={isLoggedIn ? <ExplorePage /> : <Navigate to="/login" />} />
            <Route path="/create" element={isLoggedIn ? <CreatePost /> : <Navigate to="/login" />} />
            <Route path="/activity" element={isLoggedIn ? <ActivityFeed /> : <Navigate to="/login" />} />
            <Route path="/user/:username" element={isLoggedIn ? <UserProfile /> : <Navigate to="/login" />} />
            <Route path="/edit-profile" element={isLoggedIn ? <EditProfile /> : <Navigate to="/login" />} />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/register" element={<Register setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/direct" element={isLoggedIn ? <DirectMessage /> : <Navigate to="/login" />} />
            <Route path="/edit-post/:id" element={isLoggedIn ? <EditPost /> : <Navigate to="/login" />} />
          </Routes>
        </main>
        
        <Navigation isLoggedIn={isLoggedIn} />
      </div>
    </Router>
  );
};

export default App;