import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/EditPost.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://kk.oyeoye.co.kr';

const EditPost = () => {
  const [post, setPost] = useState(null);
  const [caption, setCaption] = useState('');
  const [hashtags, setHashtags] = useState('');
  const [location, setLocation] = useState('');
  const [mediaUrls, setMediaUrls] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/posts/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setPost(response.data);
        setCaption(response.data.caption);
        setHashtags(response.data.hashtags ? response.data.hashtags.join(', ') : '');
        setLocation(response.data.location);
        setMediaUrls(response.data.media_urls || []);
      } catch (error) {
        //console.error('게시물 조회 오류:', error);
      }
    };
    fetchPost();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/api/posts/${id}`, 
        { caption, hashtags: hashtags.split(',').map(tag => tag.trim()), location },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      navigate('/');
    } catch (error) {
      //console.error('게시물 수정 오류:', error);
    }
  };

  if (!post) return <div>로딩 중...</div>;

  return (
    <div className="edit-post">
      <h2>게시물 수정</h2>
      <div className="media-preview-container">
        <div className="media-preview">
          {mediaUrls.map((url, index) => (
            <div key={index} className="media-item">
              {url.endsWith('.mp4') ? (
                <video src={`${API_URL}/${url}`} controls />
              ) : (
                <img src={`${API_URL}/${url}`} alt={`미디어 ${index + 1}`} />
              )}
            </div>
          ))}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <textarea
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
          placeholder="문구 입력..."
        />
        <input
          type="text"
          value={hashtags}
          onChange={(e) => setHashtags(e.target.value)}
          placeholder="해시태그 입력 (쉼표로 구분)"
        />
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="위치 추가"
        />
        <button type="submit">수정 완료</button>
      </form>
    </div>
  );
};

export default EditPost;