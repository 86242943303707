import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Heart, MessageCircle, Play, X } from 'lucide-react';
import '../styles/ExplorePage.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://kk.oyeoye.co.kr';

const ExplorePage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState('all');
  const [searchResults, setSearchResults] = useState({ users: [], posts: [] });
  const [popularPosts, setPopularPosts] = useState([]);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [error, setError] = useState('');
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    fetchPopularPosts();
    fetchSuggestedUsers();
  }, []);

  useEffect(() => {
    //console.log('Selected post:', selectedPost);
  }, [selectedPost]);

  const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    return { headers: { Authorization: `Bearer ${token}` } };
  };

  const fetchPopularPosts = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/search/popular-posts`, getAuthHeader());
      //console.log('Popular posts:', response.data); // 로그 추가
      setPopularPosts(response.data);
    } catch (error) {
      //console.error('Error fetching popular posts:', error);
      setError('인기 게시물을 불러오는 중 오류가 발생했습니다.');
    }
  };

  const fetchSuggestedUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/search/suggested-users`, getAuthHeader());
      setSuggestedUsers(response.data);
    } catch (error) {
      //console.error('Error fetching suggested users:', error);
      setError('추천 사용자를 불러오는 중 오류가 발생했습니다.');
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/search`, {
        params: { query: searchTerm, filter: searchFilter },
        ...getAuthHeader()
      });
      setSearchResults(response.data);
    } catch (error) {
      //console.error('Search error:', error);
      setError('검색 중 오류가 발생했습니다.');
    }
  };

  const renderMediaPreview = (post) => {
    const isVideo = post.media_urls[0].endsWith('.mp4');
    
    return (
      <div className="media-preview">
        {isVideo ? (
          <div className="video-preview">
            <video 
              src={post.media_urls[0]} 
              preload="metadata"
              poster={`${post.media_urls[0].split('.')[0]}_thumbnail.jpg`}
            />
            <div className="video-icon">
              <Play size={24} />
            </div>
          </div>
        ) : (
          <img src={post.media_urls[0]} alt={post.caption} />
        )}
        <div className="post-overlay">
          <div className="post-stats">
            <div>
              <Heart size={16} /> {post.likes_count || 0}
            </div>
            <div>
              <MessageCircle size={16} /> {post.comments_count || 0}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const openPostDetail = async (post) => {
    try {
      const response = await axios.get(`${API_URL}/api/posts/${post.id}`, getAuthHeader());
      const fullPostData = response.data;
      //console.log('Fetched post data:', fullPostData); // 로그 추가
      setSelectedPost(fullPostData);
      document.body.classList.add('modal-open');
    } catch (error) {
      //console.error('Error fetching post details:', error);
      setError('게시물 상세 정보를 불러오는 중 오류가 발생했습니다.');
    }
  };

  const closePostDetail = () => {
    setSelectedPost(null);
    document.body.classList.remove('modal-open');
  };

  const handleLike = async (postId) => {
    try {
      const response = await axios.post(`${API_URL}/api/posts/${postId}/like`, {}, getAuthHeader());
      //console.log('Like response:', response.data);
      setSelectedPost(prevPost => ({
        ...prevPost,
        is_liked: response.data.is_liked,
        likes_count: response.data.likes_count
      }));
      setPopularPosts(prevPosts => 
        prevPosts.map(post => 
          post.id === postId 
            ? { ...post, is_liked: response.data.is_liked, likes_count: response.data.likes_count }
            : post
        )
      );
    } catch (error) {
      //console.error('Error liking post:', error);
      setError('좋아요 처리 중 오류가 발생했습니다.');
    }
  };


  const renderPostDetail = () => {
    if (!selectedPost) return null;
  
    const isVideo = selectedPost.media_urls && selectedPost.media_urls[0] && selectedPost.media_urls[0].endsWith('.mp4');
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <button className="close-button" onClick={() => setSelectedPost(null)}>
            <X size={24} />
          </button>
          <div className="modal-inner">
            <div className="modal-media">
              {isVideo ? (
                <video src={selectedPost.media_urls[0]} controls />
              ) : (
                <img src={selectedPost.media_urls && selectedPost.media_urls[0]} alt={selectedPost.caption} />
              )}
            </div>
            <div className="modal-info">
              <div className="modal-user-info">
                <img 
                  src={selectedPost.user && selectedPost.user.profile_image || 'https://via.placeholder.com/32'} 
                  alt={selectedPost.user && selectedPost.user.username} 
                  className="modal-user-avatar" 
                />
                <span className="modal-username">{selectedPost.user && selectedPost.user.username}</span>
              </div>
              <p className="modal-caption">{selectedPost.caption}</p>
              <div className="post-actions">
                <div className="post-actions-left">
                  <button 
                    onClick={() => handleLike(selectedPost.id)} 
                    className={`action-button ${selectedPost.is_liked ? 'liked' : ''}`}
                  >
                    <Heart 
                      fill={selectedPost.is_liked ? '#ed4956' : 'none'} 
                      color={selectedPost.is_liked ? '#ed4956' : 'currentColor'} 
                    />
                  </button>
                  <button className="action-button">
                    <MessageCircle />
                  </button>
                </div>
              </div>
              <div className="post-info">
                <div className="post-likes">{`좋아요 ${selectedPost.likes_count}개`}</div>
                <div className="post-comments-count">{`댓글 ${selectedPost.comments_count}개`}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="explore-page">
      <div className="explore-content">
        <div className="search-bar">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="검색..."
          />
          <select value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)}>
            <option value="all">전체</option>
            <option value="users">사용자</option>
            <option value="posts">게시물</option>
          </select>
          <button onClick={handleSearch}>검색</button>
        </div>

        {error && <div className="error-message">{error}</div>}

        {(searchResults.users.length > 0 || searchResults.posts.length > 0) && (
          <div className="search-results">
            <h2>검색 결과</h2>
            <div className="results-container">
              {(searchFilter === 'all' || searchFilter === 'users') && searchResults.users.length > 0 && (
                <div className="users-results">
                  <h3>사용자</h3>
                  <div className="user-grid">
                    {searchResults.users.map((user) => (
                      <Link key={user.id} to={`/user/${user.username}`} className="user-result">
                        <div className="user-image-container">
                          <img src={user.profile_image || 'https://via.placeholder.com/80'} alt={user.username} className="user-avatar" />
                        </div>
                        <span className="username">{user.username}</span>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
              {(searchFilter === 'all' || searchFilter === 'posts') && searchResults.posts.length > 0 && (
                <div className="posts-results">
                  <h3>게시물</h3>
                  <div className="post-grid">
                    {searchResults.posts.map((post) => (
                      <div key={post.id} className="post-preview" onClick={() => openPostDetail(post)}>
                        {renderMediaPreview(post)}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="popular-posts">
          <h2>인기 게시물</h2>
          <div className="post-grid">
            {popularPosts.map((post) => (
              <div key={post.id} className="post-preview" onClick={() => openPostDetail(post)}>
                {renderMediaPreview(post)}
              </div>
            ))}
          </div>
        </div>

        <div className="suggested-users">
          <h2>추천 사용자</h2>
          <div className="suggested-users-grid">
            {suggestedUsers.map((user) => (
              <Link key={user.id} to={`/user/${user.username}`} className="suggested-user">
                <div className="suggested-user-image-container">
                  <img src={user.profile_image || 'https://via.placeholder.com/50'} alt={user.username} className="suggested-user-avatar" />
                </div>
                <div className="suggested-user-info">
                  <span className="suggested-username">{user.username}</span>
                  <span className="suggested-followers">{user.followers_count} 팔로워</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      {selectedPost && renderPostDetail()}
    </div>
  );
};

export default ExplorePage;