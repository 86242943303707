import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import Post from './Post';
import '../styles/HomeFeed.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://kk.oyeoye.co.kr';

const HomeFeed = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  const getValidImageUrl = (url) => {
    if (!url) return 'https://via.placeholder.com/300';
    if (url.startsWith('http')) return url;
    return `${API_URL}${url}`;
  };

  const fetchPosts = useCallback(async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response = await api.get(`/api/posts?page=${page}`);
      console.log('Server response:', response);

      if (!response.data || !Array.isArray(response.data)) {
        console.error('Invalid server response:', response.data);
        throw new Error('Invalid server response');
      }

      const newPosts = response.data.map(post => ({
        ...post,
        media_urls: Array.isArray(post.media_urls) ? post.media_urls.map(getValidImageUrl) : [],
        thumbnail_urls: Array.isArray(post.thumbnail_urls) ? post.thumbnail_urls.map(getValidImageUrl) : [],
        user: post.user ? {
          ...post.user,
          profile_image: getValidImageUrl(post.user.profile_image)
        } : null
      }));
      console.log('Fetched posts:', newPosts);

      if (newPosts.length === 0) {
        setHasMore(false);
      } else {
        setPosts(prevPosts => {
          const updatedPosts = [...prevPosts, ...newPosts].map(post => ({
            ...post,
            comments: Array.isArray(post.comments) ? post.comments : [],
            comments_count: post.comments_count || 0
          }));
          console.log('Updated posts:', updatedPosts);
          return updatedPosts;
        });
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      console.error('게시물 조회 오류:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  }, [page, loading, hasMore, navigate]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const handleLike = useCallback(async (postId) => {
    try {
      const response = await api.post(`/api/posts/${postId}/like`);
      setPosts(prevPosts => prevPosts.map(post => 
        post.id === postId 
          ? { ...post, likes_count: response.data.likes_count, is_liked: response.data.is_liked } 
          : post
      ));
      return response.data;
    } catch (error) {
      //console.error('좋아요 처리 중 오류:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      throw error;
    }
  }, [navigate]);

  const handleComment = useCallback(async (postId, content) => {
    try {
      const response = await api.post(`/api/posts/${postId}/comment`, { content });
      setPosts(prevPosts => prevPosts.map(post => 
        post.id === postId 
          ? { 
              ...post, 
              comments: [...(post.comments || []), response.data],
              comments_count: (post.comments_count || 0) + 1 
            } 
          : post
      ));
      return response.data;
    } catch (error) {
      //console.error('댓글 작성 중 오류:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      throw error;
    }
  }, [navigate]);

  const handleCommentDelete = useCallback(async (postId, commentId) => {
    try {
      const response = await api.delete(`/api/posts/${postId}/comments/${commentId}`);
      if (response.status === 200) {
        setPosts(prevPosts => prevPosts.map(post => 
          post.id === postId 
            ? { 
                ...post, 
                comments: (post.comments || []).filter(comment => comment.id !== commentId),
                comments_count: Math.max((post.comments_count || 0) - 1, 0)
              } 
            : post
        ));
        return true;
      }
      return false;
    } catch (error) {
      //console.error('댓글 삭제 중 오류:', error);
      return false;
    }
  }, []);

  return (
    <div className="home-feed">
      {posts.map((post) => (
        <Post 
          key={post.id}
          post={post}
          onLike={handleLike}
          onComment={handleComment}
          onCommentDelete={handleCommentDelete}
        />
      ))}
      {loading && <div>게시물을 불러오는 중...</div>}
      {!hasMore && <div>더 이상 게시물이 없습니다.</div>}
      {hasMore && !loading && <button onClick={fetchPosts} className="load-more">더 보기</button>}
    </div>
  );
};

export default HomeFeed;