import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://kk.oyeoye.co.kr';

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    //console.log('API Request:', config.method.toUpperCase(), config.url);
    return config;
  },
  (error) => {
    //console.error('API Request Error:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    //console.log('API Response:', response.status, response.config.method.toUpperCase(), response.config.url);
    return response;
  },
  (error) => {
    //console.error('API Response Error:', error.response ? error.response.status : 'Network Error', error.config ? error.config.url : 'Unknown URL');
    
    if (error.response) {
      if (error.response.status === 401 || (error.response.data && error.response.data.details === 'Token expired')) {
        //console.log('Token expired or unauthorized, redirecting to login');
        localStorage.removeItem('token');
        window.location.href = '/login';
      } else if (error.response.status === 403) {
        //console.error('Forbidden error:', error.response.data);
      }
    }
    
    return Promise.reject(error);
  }
);

export default api;