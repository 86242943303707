import React, { useState, useRef, useEffect } from 'react';
import { Heart, MessageCircle, Play, Bookmark } from 'lucide-react';
import '../styles/Post.css';

const Post = ({ post, onLike, onComment, onCommentDelete }) => {
  const [isLiked, setIsLiked] = useState(post.is_liked);
  const [likesCount, setLikesCount] = useState(post.likes_count);
  const [comments, setComments] = useState(post.comments || []);
  const [newComment, setNewComment] = useState('');
  const [showComments, setShowComments] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const postRef = useRef(null);
  const currentUserId = parseInt(localStorage.getItem('userId'));

  useEffect(() => {
    setIsLiked(post.is_liked);
    setLikesCount(post.likes_count);
    setComments(post.comments || []);
  }, [post]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting && isPlaying) {
          videoRef.current.pause();
          setIsPlaying(false);
        }
      });
    }, options);

    if (postRef.current) {
      observer.observe(postRef.current);
    }

    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
    };
  }, [isPlaying]);

  const handleLike = async () => {
    try {
      const result = await onLike(post.id);
      if (result) {
        setIsLiked(result.is_liked);
        setLikesCount(result.likes_count);
      }
    } catch (error) {
      console.error('좋아요 처리 중 오류:', error);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      const addedComment = await onComment(post.id, newComment);
      if (addedComment) {
        setComments(prevComments => [...prevComments, addedComment]);
        setNewComment('');
      }
    } catch (error) {
      console.error('댓글 작성 중 오류:', error);
    }
  };

  const handleCommentDelete = async (commentId) => {
    try {
      const success = await onCommentDelete(post.id, commentId);
      if (success) {
        setComments(prevComments => prevComments.filter(comment => comment.id !== commentId));
      } else {
        alert('댓글 삭제에 실패했습니다. 다시 시도해 주세요.');
      }
    } catch (error) {
      console.error('댓글 삭제 중 오류:', error);
      alert('댓글 삭제 중 오류가 발생했습니다.');
    }
  };

  const toggleVideoPlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const nextMedia = () => {
    setCurrentMediaIndex((prevIndex) => 
      prevIndex === post.media_urls.length - 1 ? 0 : prevIndex + 1
    );
    setIsPlaying(false);
  };

  const prevMedia = () => {
    setCurrentMediaIndex((prevIndex) => 
      prevIndex === 0 ? post.media_urls.length - 1 : prevIndex - 1
    );
    setIsPlaying(false);
  };

  const renderMediaContent = () => {
    if (!post?.media_urls || post.media_urls.length === 0) {
      return null;
    }

    const currentMedia = post.media_urls[currentMediaIndex];
    const isVideo = currentMedia?.endsWith('.mp4');

    return (
      <div className="media-container">
        <div className="media-content">
        {isVideo ? (
            <>
              <video
                ref={videoRef}
                src={currentMedia}
                poster={post.thumbnail_urls && post.thumbnail_urls[currentMediaIndex]}
                loop
                playsInline
                onClick={toggleVideoPlay}
              />
              {!isPlaying && (
                <button className="play-button" onClick={toggleVideoPlay}>
                  <Play size={24} />
                </button>
              )}
            </>
          ) : (
            <img src={currentMedia} alt={`게시물 이미지 ${currentMediaIndex + 1}`} />
          )}
        </div>
        {post.media_urls.length > 1 && (
          <>
            <button className="prev" onClick={prevMedia}>&#10094;</button>
            <button className="next" onClick={nextMedia}>&#10095;</button>
            <div className="slide-counter">
              {currentMediaIndex + 1} / {post.media_urls.length}
            </div>
          </>
        )}
      </div>
    );
  };
  
  const renderHashtags = () => {
    if (!post.hashtags || post.hashtags.length === 0) return null;
    
    return (
      <div className="post-hashtags">
        {post.hashtags.map((tag, index) => (
          <span key={index} className="hashtag">
            {tag}
          </span>
        ))}
      </div>
    );
  };

  const renderComments = () => {
    return (
      <div className="post-comments">
        {comments.map((comment) => (
          <div key={comment.id} className="comment">
            <img 
              src={comment.profile_image || 'https://via.placeholder.com/32'} 
              alt={comment.username} 
              className="comment-avatar"
            />
            <div className="comment-content">
              <span className="comment-username">{comment.username}</span>
              <span className="comment-text">{comment.content}</span>
            </div>
            {(comment.user_id === currentUserId || post.user_id === currentUserId) && (
              <button onClick={() => handleCommentDelete(comment.id)} className="delete-comment">
                삭제
              </button>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="post" ref={postRef}>
      <div className="post-header">
        <img src={post.user.profile_image || 'https://via.placeholder.com/32'} alt={post.user.username} className="post-avatar" />
        <span className="post-username">{post.user.username}</span>
      </div>
      {renderMediaContent()}
      <div className="post-actions">
        <div className="post-actions-left">
          <button onClick={handleLike} className={`action-button ${isLiked ? 'liked' : ''}`}>
            <Heart fill={isLiked ? '#ed4956' : 'none'} color={isLiked ? '#ed4956' : 'currentColor'} />
          </button>
          <button onClick={() => setShowComments(!showComments)} className="action-button">
            <MessageCircle />
          </button>
        </div>
        <div className="post-actions-right">
          <button className="action-button">
            <Bookmark />
          </button>
        </div>
      </div>
      <div className="post-info">
        <div className="post-likes">{`${likesCount} 좋아요`}</div>
        <div className="post-caption">
          <span className="post-username">{post.user.username}</span> {post.caption}
        </div>
        {renderHashtags()}
        <button className="view-comments-button" onClick={() => setShowComments(!showComments)}>
          {comments.length > 0 ? `댓글 ${comments.length}개 모두 보기` : '댓글 달기'}
        </button>
      </div>
      {showComments && renderComments()}
      <form onSubmit={handleCommentSubmit} className="comment-form">
        <input
          type="text"
          placeholder="댓글 추가..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
        <button type="submit">게시</button>
      </form>
    </div>
  );
};

export default Post;