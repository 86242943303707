import React, { useState, useEffect } from 'react';
import api from '../api';
import { useParams, useNavigate, Link } from 'react-router-dom';
import '../styles/UserProfile.css';
import { Heart, MessageCircle, Play, Edit3, Trash2 } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL || 'https://kk.oyeoye.co.kr';

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const { username } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!username) {
        setError('사용자 이름이 제공되지 않았습니다.');
        return;
      }

      try {
        const response = await api.get(`/api/users/${encodeURIComponent(username)}`);
        setUser(response.data.user);
        setPosts(response.data.posts);
        setIsFollowing(response.data.isFollowing);
        setIsOwnProfile(response.data.isOwnProfile);
      } catch (error) {
        //console.error('프로필 로딩 중 오류:', error);
        if (error.response) {
          //console.error('Error response:', error.response.data);
          if (error.response.status === 404) {
            setError('사용자를 찾을 수 없습니다.');
          } else if (error.response.status === 500) {
            setError(`서버 오류: ${error.response.data.details || '알 수 없는 오류'}`);
          } else {
            setError(`오류 발생: ${error.response.data.error || '알 수 없는 오류'}`);
          }
        } else if (error.request) {
          //console.error('Error request:', error.request);
          setError('서버에서 응답이 없습니다.');
        } else {
          //console.error('Error message:', error.message);
          setError('네트워크 오류가 발생했습니다.');
        }
      }
    };

    fetchUserProfile();
  }, [username, navigate]);

  const handleFollowToggle = async () => {
    try {
      const response = await api.post(`/api/users/${user.id}/follow`);
      setIsFollowing(response.data.isFollowing);
      setUser(prevUser => ({
        ...prevUser,
        followers_count: response.data.isFollowing 
          ? prevUser.followers_count + 1 
          : prevUser.followers_count - 1
      }));
    } catch (error) {
      //console.error('팔로우/언팔로우 중 오류:', error);
      setError('팔로우/언팔로우 처리 중 오류가 발생했습니다.');
    }
  };

  const handleEditPost = (postId) => {
    navigate(`/edit-post/${postId}`);
  };

  const handleDeletePost = async (postId) => {
    try {
      await api.delete(`/api/posts/${postId}`);
      setPosts(posts.filter(post => post.id !== postId));
    } catch (error) {
      //console.error('게시물 삭제 중 오류:', error);
      setError('게시물 삭제 중 오류가 발생했습니다.');
    }
  };

  const renderMediaPreview = (post) => {
    const isVideo = post.media_urls[0].endsWith('.mp4');
    
    return (
      <div className="media-preview">
        {isVideo ? (
          <div className="video-preview">
            <video 
              src={post.media_urls[0]} 
              preload="metadata"
              poster={`${post.media_urls[0].split('.')[0]}_thumbnail.jpg`}
            />
            <div className="video-icon">
              <Play size={24} />
            </div>
          </div>
        ) : (
          <img src={post.media_urls[0]} alt={post.caption} />
        )}
        <div className="post-overlay">
          <div className="post-stats">
            <div>
              <Heart size={16} /> {post.likes_count || 0}
            </div>
            <div>
              <MessageCircle size={16} /> {post.comments_count || 0}
            </div>
          </div>
          {isOwnProfile && (
            <div className="post-actions">
              <Edit3 size={16} onClick={() => handleEditPost(post.id)} />
              <Trash2 size={16} onClick={() => handleDeletePost(post.id)} />
            </div>
          )}
        </div>
      </div>
    );
  };

  const getProfileImageUrl = (imagePath) => {
    if (!imagePath) return 'https://via.placeholder.com/150';
    if (imagePath.startsWith('http')) return imagePath;
    // 수정: 서버에서 반환하는 경로 형식과 일치하도록 수정
    return `${API_URL}${imagePath}?t=${new Date().getTime()}`;
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!user) {
    return <div>로딩 중...</div>;
  }

  return (
    <div className="user-profile">
      <div className="profile-header">
        <img 
          src={getProfileImageUrl(user.profile_image)} 
          alt={user.username} 
          className="profile-image" 
        />
        <div className="profile-info">
          <div className="profile-username">
            <h2>{user.username}</h2>
            {!isOwnProfile && (
              <button onClick={handleFollowToggle} className={`follow-button ${isFollowing ? 'following' : ''}`}>
                {isFollowing ? '팔로잉' : '팔로우'}
              </button>
            )}
            {isOwnProfile && (
              <Link to="/edit-profile" className="edit-profile-button">프로필 편집</Link>
            )}
          </div>
          <div className="profile-stats">
            <span><strong>{posts.length}</strong> 게시물</span>
            <span><strong>{user.followers_count}</strong> 팔로워</span>
            <span><strong>{user.following_count}</strong> 팔로잉</span>
          </div>
          <p className="profile-bio">{user.bio || '소개가 없습니다.'}</p>
        </div>
      </div>
      <div className="profile-posts">
        {posts.map(post => (
          <div key={post.id} className="profile-post">
            {renderMediaPreview(post)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserProfile;