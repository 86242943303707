import React, { useState } from 'react';
import axios from 'axios';
import '../styles/CreatePost.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://kk.oyeoye.co.kr';
const VIDEO_OPTIMIZER_URL = process.env.REACT_APP_VIDEO_OPTIMIZER_URL || 'http://localhost:3002';

const CreatePost = () => {
  const [media, setMedia] = useState([]);
  const [caption, setCaption] = useState('');
  const [hashtagInput, setHashtagInput] = useState('');
  const [hashtags, setHashtags] = useState([]);
  const [location, setLocation] = useState('');
  const [previewUrls, setPreviewUrls] = useState([]);

  const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB

  const handleMediaChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(file => file.size <= MAX_FILE_SIZE);
    if (validFiles.length !== files.length) {
      alert('100MB 이하의 파일만 업로드 가능합니다.');
    }
    setMedia(prevMedia => [...prevMedia, ...validFiles]);
  
    const newUrls = validFiles.map(file => ({
      url: URL.createObjectURL(file),
      type: file.type.startsWith('video/') ? 'video' : 'image'
    }));
    setPreviewUrls(prevUrls => [...prevUrls, ...newUrls]);
  };

  const removeMedia = (index) => {
    setMedia(prevMedia => prevMedia.filter((_, i) => i !== index));
    setPreviewUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
  };

  const handleHashtagChange = (e) => {
    const input = e.target.value;
    setHashtagInput(input);
    
    const tags = input.split(/\s+/).filter(word => word.startsWith('#'));
    setHashtags(tags);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      
      for (let i = 0; i < media.length; i++) {
        const file = media[i];
        console.log('File being processed:', file.name, file.type, file.size);
        
        if (file.type.startsWith('video/')) {
          try {
            console.log('Attempting to optimize video:', file.name);
            const videoFormData = new FormData();
            videoFormData.append('video', file);
            
            const videoOptimizeResponse = await axios.post(`${VIDEO_OPTIMIZER_URL}/optimize`, videoFormData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            });
            
            console.log('Video optimization response:', videoOptimizeResponse.data);

            if (videoOptimizeResponse.data.optimizedVideoPath) {
              formData.append('media', videoOptimizeResponse.data.optimizedVideoPath);
            }

            if (videoOptimizeResponse.data.thumbnailPath) {
              formData.append('thumbnails', videoOptimizeResponse.data.thumbnailPath);
            }
          } catch (optimizeError) {
            console.error('비디오 최적화 또는 썸네일 생성 실패:', optimizeError);
            console.error('Error details:', optimizeError.response ? optimizeError.response.data : optimizeError.message);
          }
        } else {
          formData.append('media', file);
        }
      }
      
      formData.append('caption', caption);
      formData.append('hashtags', hashtags.join(','));
      formData.append('location', location);

      const response = await axios.post(`${API_URL}/api/posts`, formData, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      
      console.log('게시물 생성 성공:', response.data);
      alert('게시물이 생성되었습니다.');
      setMedia([]);
      setCaption('');
      setHashtagInput('');
      setHashtags([]);
      setLocation('');
      setPreviewUrls([]);
    } catch (error) {
      console.error('게시물 생성 오류:', error);
      console.error('Error details:', error.response ? error.response.data : error.message);
      alert('게시물 생성에 실패했습니다. 자세한 내용은 콘솔을 확인해주세요.');
    }
  };


  return (
    <div className="create-post">
      <h2>새 게시물 만들기</h2>
      <form onSubmit={handleSubmit}>
        <div className="media-upload">
          <label htmlFor="media-input" className="media-input-label">
            미디어 파일 선택
          </label>
          <input
            id="media-input"
            type="file"
            accept="image/*, video/*"
            multiple
            onChange={handleMediaChange}
            className="media-input"
          />
        </div>
        <div className="media-preview">
          {previewUrls.map((item, index) => (
            <div key={index} className="media-item">
              {item.type === 'image' ? (
                <img src={item.url} alt={`Preview ${index}`} className="preview-image" />
              ) : (
                <video src={item.url} className="preview-video" controls />
              )}
              <button type="button" onClick={() => removeMedia(index)} className="remove-media">
                &times;
              </button>
            </div>
          ))}
        </div>
        <textarea
          placeholder="문구 입력..."
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="#해시태그 입력 (띄어쓰기로 구분)"
          value={hashtagInput}
          onChange={handleHashtagChange}
        />
        <input
          type="text"
          placeholder="위치 추가"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
        <button type="submit">공유하기</button>
      </form>
    </div>
  );
};

export default CreatePost;